// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination, FreeMode, Thumbs } from 'swiper';

export default () => {
  var wlySwiper = new Swiper('.swiper-container-wly', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    spaceBetween: 30,
    autoplay: {
      delay: 7000,
    },
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });


  var logoSwiper = new Swiper('.swiper-container-logo', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    slidesPerView: 3,
    spaceBetween: 30,
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 60,
      },
      993: {
        slidesPerView: 4,
        spaceBetween: 80,
      },
      1201: {
        slidesPerView: 5,
        spaceBetween: 100,
      }
    },
    grabCursor: true
  });


  var testimonialThumbSwiper = new Swiper('.swiper-container-testimonials-thumb', {
    modules: [Navigation, Pagination, Autoplay, FreeMode],
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    speed: 1400,
    slidesPerView: 1,
    spaceBetween: 20,
    freeMode: true,
    breakpoints: {
      600: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      993: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1341: {
        slidesPerView: 4,
        spaceBetween: 20,
      }
    },
    grabCursor: true,
    watchSlidesProgress: true
  });


  var testimonialSwiper = new Swiper('.swiper-container-testimonials', {
    modules: [Navigation, Pagination, Autoplay, Thumbs],
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    speed: 1400,
    spaceBetween: 50,
    grabCursor: true,
    thumbs: {
      swiper: testimonialThumbSwiper,
    },
  });


  // Funktion, um den Teaserbox Swiper zu initialisieren
  function initSwiper() {
    return new Swiper('.swiper-container-teaserboxfive', {
      modules: [Navigation, Pagination, Autoplay],
      direction: 'horizontal',
      speed: 1400,
      slidesPerView: 1,
      spaceBetween: 17,
      grabCursor: true,
      breakpoints: {
        600: {
          slidesPerView: 2,
          spaceBetween: 17,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });
  }

  var teaserboxSwiper;

  function handleResize() {
    if (window.innerWidth < 768) {
      if (!teaserboxSwiper) {
        teaserboxSwiper = initSwiper();
      }
    } else {
      if (teaserboxSwiper) {
        teaserboxSwiper.destroy(true, true);
        teaserboxSwiper = null;
      }
    }
  }

  // Event-Listener für das Resizing des Fensters
  window.addEventListener('resize', handleResize);

  // Initiale Überprüfung bei Seitenladen
  handleResize();

};
