import { onReady } from 'wly-theme-extensions';
import { InitializeForms } from './form.js';
import { updateActiveItem } from './navigation.ts';
import Accordion from './accordion.ts';
import Autosize from './autosize.ts';
import Swiper from './swiper.ts';
import lazySizes from 'lazysizes';
//import Menu from './mmenu';
import Teich from './teich';

import './baguette-box';
import './googlemapsinfobox';
import './masonry';


onReady(() => {
  //Menu();
  updateActiveItem();
  lazySizes.init();
  Accordion();
  Autosize();
  Swiper();
  InitializeForms();
  Teich();
});
