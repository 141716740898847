<template>
  <div class="paginator__items" ref="grid">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, PropType, ref, UnwrapRef, watch } from 'vue';
import Masonry from "masonry-layout";
import baguetteBox from 'baguettebox.js';

const props = defineProps({
  watch: {
    type: Array as PropType<Array<UnwrapRef<any>>>,
    required: false,
  },
});

const grid = ref<HTMLElement>();
const currentResizeObserverElements = ref<Array<any>>([]);
let masonry: Masonry = null;

const gridResizeObserver = new ResizeObserver((items) => {
  initBaguetteBox()

  if(!masonry)
    initMasonry()
  else {
    masonry.reloadItems()
    masonry.layout()
  }
});


const initBaguetteBox = () => {
  baguetteBox.run('.paginator__items', {
    noScrollbars: true,
    async: true,
    titleTag: true,
  });
}

const initMasonry = () => {
  masonry = new Masonry(grid.value, {
    itemSelector: '.wly-masonry-item',
    columnWidth: 0.1
  });
}

const allItems = () => {
  return Array.from(grid.value?.children ?? []);
};

function applyResizeObserver() {
  // Unobserves any existing elements and then re-applies the elements to be observed
  currentResizeObserverElements.value.forEach((item) => gridResizeObserver.unobserve(item));
  currentResizeObserverElements.value.splice(0, currentResizeObserverElements.value.length);

  const items = allItems().map((item) => item.firstElementChild);
  currentResizeObserverElements.value.push(...items);

  items.forEach((item) => {
    gridResizeObserver.unobserve(item!);
    gridResizeObserver.observe(item!, { box: 'content-box' });
  });
}

props.watch &&
watch(
    props.watch!,
    () => {
      // Wait for the elements to be rendered, then apply the observer
      nextTick(() => {
        applyResizeObserver();
      });
    },
    {
      immediate: true,
    }
);
</script>