import $ from 'jquery';
import Rellax from 'rellax';

export default () => {
  // Scroll to
  $(document).on('click', 'a[href]:not([href^="mailto\\:"], [href$="\\#"])', function (event) {
    function isValidSelector(selector) {
      if (typeof selector !== 'string') {
        return false;
      }
      try {
        var $element = $(selector);
      } catch (error) {
        return false;
      }
      return true;
    }

    if (isValidSelector($(this).attr('href'))) {
      if ($($.attr(this, 'href')).length && $.attr(this, 'href') !== '#wlymmenu' && $.attr(this, 'href') !== '#') {
        event.preventDefault();
        $('html, body').animate(
          {
            scrollTop: $($.attr(this, 'href')).offset().top - $('header').height(),
          },
          1000
        );
      }
    }
  });


  // Mobile Menü
  $('.nav-toggler').click(function() {
    $('.mobile__menu').slideToggle();
    $('body').toggleClass('mobile-menu-active');
  });

  // Open Language Switcher
  $('.locale-switcher').click(function (){
    $(this).find('.locale-switcher__dropdown').toggleClass('active');
  });

  // Navigation Touch Geräte
  $('#headerNavbar ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
    if (!$(this).parent().hasClass('hovered')) {
      e.preventDefault();
      $(this).parent().toggleClass('hovered');
      $(this).parent().siblings().removeClass('hovered');
    }
  });

  const hoveredItems = $('[class*="hovered"]');

  hoveredItems.each(function () {
    $(this).removeClass('.hovered');
  });


  // Navigation Controller
  $('.header-nav .main-item').click(function(e) {
    e.stopPropagation();
    $('.dropdown-menu > li.open').removeClass('open');
    $(this).closest('li').find('.mega-menu').removeClass('submen--open');
    $(this).closest('li').find('.dropdown-submenu').find('li').removeClass('open');
    $(this).closest('li').toggleClass('open');
    $(this).closest('li').siblings('.open').removeClass('open');

    // Mega Menü Hight Calc
    var t = 0; // the height of the highest element (after the function runs)
    var childElem = $(this).closest('.nav-menu').find('.dropdown');
    $(childElem).each(function () {
      var elem = $(this);
      elem.height('auto');
      if (elem.height() > t) {
        t = elem.height();
      }
    });
    $(childElem).height(t);
  });
  $('.dropdown-menu > li > .dropdown-item').click(function(e) {
    e.stopPropagation();
    $(this).closest('li').addClass('open');
    $(this).closest('li').siblings('.open').removeClass('open');
  });
  $('.dropdown-submenu > ul > li > div.dropdown-item, .menu--goes-direct-deeper > .dropdown-item').click(function(e) {
    console.log('test')
    e.stopPropagation();
    $(this).closest('li').addClass('open');
    $(this).closest('.mega-menu').addClass('submen--open');
    $(this).closest('li').siblings('.open').removeClass('open');
    $(this).closest('.navbar__wrapper').addClass('subsub--open');
  });
  $('.dropdown-subsubmenu .menu--back').click(function(e) {
    e.stopPropagation();
    $(this).closest('li').removeClass('open');
    $(this).closest('.mega-menu').removeClass('submen--open');
    $(this).closest('.navbar__wrapper').removeClass('subsub--open');
  });

  $(document).click(function(event) {
    // Prüfen, ob der Klick außerhalb des Mega-Menü-Wrappers erfolgt ist
    if (!$(event.target).closest('.mega-menu').length && !$(event.target).closest('.header-nav .main-item').length) {
      $('.header-nav li.open').removeClass('open');
      $('.mega-menu').removeClass('submen--open');
      $('.navbar__wrapper').removeClass('subsub--open');
      $('.dropdown-submenu li.open').removeClass('open');
    }
  });



  // Make fixed Navigation
  var previousScroll = 0;
  var scrollThreshold = 250;

  $(window).scroll(function() {
    var currentScroll = $(this).scrollTop();

    if (currentScroll > scrollThreshold) {
      $('body').addClass('fixed-header');

      if (currentScroll > previousScroll) {
        $('body').addClass('fixed-header-away');
      } else {
        $('body').removeClass('fixed-header-away');
      }
    } else {
      $('body').removeClass('fixed-header fixed-header-away');
    }

    previousScroll = currentScroll;
  });


  // Init Rellax
  var rellax = new Rellax('.rellax');


  // Funktion zur Berechnung und Einstellung der anfänglichen Parallax-Position
  function setInitialParallaxPosition() {
    $('.intro__image').each(function() {
      var $element = $(this);
      var factor = 0.07; // Gleicher Wert wie in der Paroller-Initialisierung
      var offset = $element.offset().top;
      var scrollTop = $(window).scrollTop();
      var windowHeight = $(window).height();
      var newPos = ((scrollTop - offset + windowHeight) * factor);

      // Setze die anfängliche transform-Eigenschaft
      $element.css('transform', 'translateY(' + newPos + 'px)');
    });
  }

  // Setze die Position beim Laden der Seite
  setInitialParallaxPosition();

  // Aktualisiere die Position beim Scrollen
  $(window).on('scroll', function() {
    setInitialParallaxPosition();
  });

  
  
  // Video File Banner Play
  $('.video__banner').click(function() {
    $(this).hide(); // Versteckt das Banner-Bild
    $(this).siblings('.bgvid').addClass('show'); // Zeigt das Video an
    $(this).siblings('.bgvid')[0].play(); // Startet das Video
  });
  $('.bgvid').on('pause', function() {
    $(this).removeClass('show'); // Versteckt das Video
    $(this).siblings('.video__banner').show(); // Zeigt das Banner-Bild wieder an
  });

  //Video automatisch abspielen
  function checkVideoVisibility() {
    $('.bgvidauto').each(function() {
      var video = $(this)[0];
      var rect = video.getBoundingClientRect();
      var windowHeight = $(window).height();
      var videoHeight = rect.bottom - rect.top;

      // Überprüfen, ob mindestens 20% des Videos sichtbar sind
      var isVisible = (rect.top < windowHeight && rect.bottom > 0 && (rect.bottom - Math.max(rect.top, 0)) / videoHeight >= 0.2);

      if (isVisible) {
        video.play();
      } else {
        video.pause();
      }
    });
  }

  $(window).on('scroll resize', function() {
    checkVideoVisibility();
  });

  // Initial check in case the video is already in view
  checkVideoVisibility();



  // Schritte
  $('.multistep').each(function() {
    var $images = $(this).find('.multistep__images__inner');
    var $window = $(window);
    var $textContent = $(this).find('.multistep__items');

    function checkScroll() {
      var scrollTop = $window.scrollTop();
      var sectionTop = $textContent.offset().top;
      var sectionHeight = $textContent.outerHeight();
      var windowHeight = $window.height();
      var imageHeight = $images.outerHeight();

      // Berechne den unteren Rand der Sektion
      var sectionBottom = sectionTop + sectionHeight;

      // Berechne die Scroll-Position relativ zur Sektion
      var scrollPositionInSection = scrollTop - sectionTop + imageHeight;

      // Berechne den Fortschritt in Prozent relativ zur Sektion
      var scrollPercentInSection = scrollPositionInSection / (sectionHeight / 100);


      if (scrollPercentInSection < 50) {
        $images.removeClass('active--two','active--three');
        $images.addClass('active--one');
      } else if (scrollPercentInSection >= 50 && scrollPercentInSection < 80) {
        $images.removeClass('active--three','active--one');
        $images.addClass('active--two');
      } else {
        $images.addClass('active--three');
      }
    }
    $window.on('scroll', checkScroll);
    checkScroll(); // Initialer Check beim Laden der Seite
  });

};
