<template>
  <PostsFilter :paginator="paginator">
    <template v-slot:default="{ taxonomies, toggle, selectedTerms, loading, paginator }">
      <div class="paginator-posts-filter">
        <div class="paginator-posts-filter__inner">
          <template v-for="taxonomyOption in taxonomies" :key="taxonomyOption.slug">
            <Dropdown
                v-if="taxonomyOption.options?.length && !singleTaxonomySelect"
                v-model="selectedTerms[taxonomyOption.slug]"
                :options="taxonomyOption.options"
                optionLabel="label"
                optionValue="id"
                optionDisabled="disabled"
                :loading="loading"
                :disabled="loading"
                :placeholder="taxonomyOption.label"
                @change="toggle(taxonomyOption.slug, $event.value)"
            />
            <SingleTaxonomySelect
                v-if="taxonomyOption.options?.length && singleTaxonomySelect"
                v-model="selectedTerms[taxonomyOption.slug]"
                :options="taxonomyOption.options"
                optionLabel="label"
                optionValue="id"
                optionDisabled="disabled"
                :loading="loading"
                :disabled="loading"
                :placeholder="taxonomyOption.label"
                @change="toggle(taxonomyOption.slug, $event.value)"
                />
          </template>
        </div>
      </div>
      <div v-if="showSelectedOption && selectedOption">
        <h3>{{ selectedTaxonomy}}: {{ selectedOption }}</h3>
      </div>
    </template>
  </PostsFilter>
</template>

<script lang="ts" setup>
import {PostsFilter, PaginatorType} from 'wly-theme-extensions';
import Dropdown from '@/js/vue/components/Dropdown.vue';
import SingleTaxonomySelect from "@/js/vue/components/SingleTaxonomySelect.vue";
import {PropType, watch, onMounted, onUpdated} from "vue";

//defineProps(['paginator']);
const props = defineProps({
  paginator: {
    type: Object as PropType<PaginatorType>,
    required: true,
  },
  singleTaxonomySelect: {
    type: Boolean,
    default: () => false
  },
  showSelectedOption: {
    type: Boolean,
    default: () => true
  }
})

const {taxonomyFilterItems} = props.paginator;

let selectedOption = null;
let selectedTaxonomy = null;

watch(taxonomyFilterItems.value, (v) => {
  if (Object.keys(v).length !== 1 || props.singleTaxonomySelect)
    return
  for (const prop in v) {
    const taxonomie = props.paginator.taxonomies.find((t) => t.slug === prop);
    const term = taxonomie.terms.find((t) => t.term_id === v[prop][0]);
    selectedTaxonomy = taxonomie.label
    selectedOption = term.name
  }
})
</script>

<style lang="scss" scoped>

.wte-dropdown {
  max-width: 100%;
}
</style>
