<template>
  <Paginator :config="{...config, immediateFetch: false }" ref="paginator">
    <template v-slot="{ paginator, toPostComponent, hideFilter, noEntriesFoundText, loadMore }">
      <PostsFilter v-if="!hideFilter"
                   :paginator="paginator"
                   :single-taxonomy-select="singleTaxonomySelect"
                   :show-selected-option="showSelectedOption"
      />
      <Masonry v-if="masonry" :watch="[mergedPosts(paginator)]">
        <component v-if="!paginator.hasActiveFilter.value" v-for="post in selectedPosts"
                   :key="post.id" :is="toPostComponentSelected(post.id)"></component>
        <component v-for="post in paginator.posts.value"
                   :key="post.id" :is="toPostComponent(post.id)"></component>
      </Masonry>
      <div v-else class="paginator__items">
        <slot name="posts" v-bind="{ paginator, toPostComponent }">
          <component v-for="post in paginator.posts.value" :key="post.id" :is="toPostComponent(post.id)"></component>
        </slot>
      </div>

      <div v-if="noEntriesFoundText && !paginator.posts.value.length && !paginator.loading.value">
        <h4>{{ noEntriesFoundText }}</h4>
      </div>

      <div v-if="loadMore && paginator.hasNextPage.value" class="load-more__wrapper">
        <div class="button button--secondary button--load-more" @click="paginator.loadMore()">
          <InlineSpinner v-if="paginator.loading.value"/>
          <span>{{ $t('load_more') }}</span>
        </div>
      </div>
    </template>
  </Paginator>
</template>

<script setup lang="ts">
import PostsFilter from '@/js/vue/components/Filter.vue';
import {InlineSpinner, Paginator, PaginatorType} from 'wly-theme-extensions';
import { computed, defineComponent, onMounted, ref } from 'vue';
import Masonry from "@/js/vue/components/Masonry.vue";
import axios from 'axios';

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  masonry: {
    type: Boolean,
    default: () => false,
  },
  queryType: {
    type: String,
    default: () => 'config'
  },
  selectedItems: {
    type: Array
  },
  singleTaxonomySelect: {
    type: Boolean,
    default: () => false
  },
  showSelectedOption: {
    type: Boolean,
    default: () => true
  }
});

const selectedPosts = ref([]);
const paginator = ref<PaginatorType>();
const components = {};
const definePostComponent = (template: string) =>
  defineComponent({
    name: 'wte-paginator-post',
    template,
  });

const toPostComponentSelected = (id: number) => components[id];

onMounted(() => {
  if (!props.selectedItems?.length) {
    paginator.value.toFirstPage();
  } else {
    let url = props.config.url.replace('exclude', 'include');

    axios.get(url)
      .then((res) => res.data)
      .then((data) => {
        const { posts: _posts } = data;
        selectedPosts.value.push(..._posts)
        _posts.forEach((post) => {
          if (!components.hasOwnProperty(post.id)) {
            components[post.id] = definePostComponent(post.content);
          }
        });
      })
  }
})

const mergedPosts = (paginator: PaginatorType) => computed(() => {
  return selectedPosts.value.concat(paginator.posts.value);
});
</script>
